<template>
  <div class="house-change-container">
    <Header :info="searchInfo" />
    <el-row
      :gutter="20"
      style="
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 40px;
      "
    >
      <el-col :span="24" :xs="24" style="margin-bottom: 20px"
        ><p style="font-size: 18px; font-weight: 800">房源变化（日）</p></el-col
      >
      <!--用户数据-->
      <el-col :span="24" :xs="24">
        <el-form
          :model="queryParamsDay"
          ref="dayRef"
          :rules="dayRules"
          :inline="true"
          label-width="80px"
        >
          <el-form-item label="开始时间" prop="dateStart">
            <el-date-picker
              v-model="queryParamsDay.dateStart"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择开始日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" prop="dateEnd">
            <el-date-picker
              v-model="queryParamsDay.dateEnd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择结束时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="dayHandleQuery"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
        <el-table
          v-loading="dayLoading"
          :data="dayList"
          border
          style="width: 100%"
        >
          <el-table-column
            label="编号"
            align="center"
            type="index"
            width="100"
          />
          <el-table-column label="日期" align="center" prop="dateTime" />

          <el-table-column
            label="新增数量"
            align="center"
            prop="xzHouseCount"
          />
          <el-table-column
            label="上架数量"
            align="center"
            prop="sjHouseCount"
          />
          <el-table-column
            label="下架数量"
            align="center"
            prop="xjHouseCount"
          />

          <el-table-column
            label="删除数量"
            align="center"
            prop="scHouseCount"
          />
        </el-table>
        <pagination
          v-show="dayTotal > 0"
          :total="dayTotal"
          :page.sync="queryParamsDay.pageNum"
          :limit.sync="queryParamsDay.pageSize"
          @pagination="getDayList"
        />
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      style="
        margin-top: 50px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 40px;
      "
    >
      <el-col :span="24" :xs="24" style="margin-bottom: 20px"
        ><p style="font-size: 18px; font-weight: 800">房源变化（月）</p></el-col
      >
      <!--用户数据-->
      <el-col :span="24" :xs="24">
        <el-form
          :model="queryParamsMonth"
          ref="monthRef"
          :inline="true"
          :rules="monthRules"
          label-width="80px"
        >
          <el-form-item label="开始时间" prop="dateStart">
            <el-date-picker
              v-model="queryParamsMonth.dateStart"
              value-format="yyyy-MM"
              type="month"
              placeholder="选择开始日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" prop="dateEnd">
            <el-date-picker
              v-model="queryParamsMonth.dateEnd"
              value-format="yyyy-MM"
              type="month"
              placeholder="选择结束时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="monthHandleQuery"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
        <el-table
          v-loading="monthLoading"
          :data="monthList"
          border
          style="width: 100%"
        >
          <el-table-column
            label="编号"
            align="center"
            type="index"
            width="100"
          />
          <el-table-column label="日期" align="center" prop="dateTime" />

          <el-table-column
            label="新增数量"
            align="center"
            prop="xzHouseCount"
          />
          <el-table-column
            label="上架数量"
            align="center"
            prop="sjHouseCount"
          />
          <el-table-column
            label="下架数量"
            align="center"
            prop="xjHouseCount"
          />

          <el-table-column
            label="删除数量"
            align="center"
            prop="scHouseCount"
          />
        </el-table>
        <pagination
          v-show="monthTotal > 0"
          :total="monthTotal"
          :page.sync="queryParamsMonth.pageNum"
          :limit.sync="queryParamsMonth.pageSize"
          @pagination="getMonthList"
        />
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      style="
        margin-top: 50px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 40px;
      "
    >
      <el-col :span="24" :xs="24" style="margin-bottom: 20px"
        ><p style="font-size: 18px; font-weight: 800">房源变化（年）</p></el-col
      >
      <!--用户数据-->
      <el-col :span="24" :xs="24">
        <el-form
          :model="queryParamsYear"
          ref="yearRef"
          :rules="yearRules"
          :inline="true"
          label-width="80px"
        >
          <el-form-item label="开始时间" prop="dateStart">
            <el-date-picker
              v-model="queryParamsYear.dateStart"
              value-format="yyyy"
              type="year"
              placeholder="选择开始日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" prop="dateEnd">
            <el-date-picker
              v-model="queryParamsYear.dateEnd"
              value-format="yyyy"
              type="year"
              placeholder="选择结束时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="yearHandleQuery"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
        <el-table
          v-loading="yearLoading"
          :data="yearList"
          border
          style="width: 100%"
        >
          <el-table-column
            label="编号"
            align="center"
            type="index"
            width="100"
          />
          <el-table-column label="日期" align="center" prop="dateTime" />

          <el-table-column
            label="新增数量"
            align="center"
            prop="xzHouseCount"
          />
          <el-table-column
            label="上架数量"
            align="center"
            prop="sjHouseCount"
          />
          <el-table-column
            label="下架数量"
            align="center"
            prop="xjHouseCount"
          />

          <el-table-column
            label="删除数量"
            align="center"
            prop="scHouseCount"
          />
        </el-table>
        <pagination
          v-show="yearTotal > 0"
          :total="yearTotal"
          :page.sync="queryParamsYear.pageNum"
          :limit.sync="queryParamsYear.pageSize"
          @pagination="getYearList"
        />
      </el-col>
    </el-row>
  </div>
</template>
  
  <script>
import Header from "@/components/Header.vue";
import Pagination from "@/components/Pagination";
export default {
  name: "HouseChange",
  components: { Header, Pagination },
  data() {
    return {
      // header数据
      searchInfo: null,
      // 日总数
      dayTotal: 0,
      // 月总数
      monthTotal: 0,
      // 年总数
      yearTotal: 0,
      // 日查询参数
      queryParamsDay: {
        pageNum: 1,
        pageSize: 10,
        dateStart: "",
        dateEnd: "",
        dateType: "r",
      },
      // 月查询参数
      queryParamsMonth: {
        pageNum: 1,
        pageSize: 10,
        dateStart: "",
        dateEnd: "",
        dateType: "y",
      },
      // 年查询参数
      queryParamsYear: {
        pageNum: 1,
        pageSize: 10,
        dateStart: "",
        dateEnd: "",
        dateType: "n",
      },
      //日表格加载
      dayLoading: false,
      //月表格加载
      monthLoading: false,
      //年表格加载
      yearLoading: false,
      //日表格数据
      dayList: null,
      //月表格数据
      monthList: null,
      //年表格数据
      yearList: null,
      // 日校验
      dayRules: {
        dateStart: [
          { required: true, message: "开始时间不能为空", trigger: "blur" },
        ],
        dateEnd: [
          { required: true, message: "结束时间不能为空", trigger: "blur" },
        ],
      },
      // 月校验
      monthRules: {
        dateStart: [
          { required: true, message: "开始时间不能为空", trigger: "blur" },
        ],
        dateEnd: [
          { required: true, message: "结束时间不能为空", trigger: "blur" },
        ],
      },
      // 年校验
      yearRules: {
        dateStart: [
          { required: true, message: "开始时间不能为空", trigger: "blur" },
        ],
        dateEnd: [
          { required: true, message: "结束时间不能为空", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.searchInfo = this.$route.query;
    this.searchInfo.active = 4;
    this.queryParamsDay.dateStart = this.getDay();
    this.queryParamsDay.dateEnd = this.getDay();
    this.queryParamsMonth.dateStart = this.getMonth();
    this.queryParamsMonth.dateEnd = this.getMonth();
    this.queryParamsYear.dateStart = this.getYear();
    this.queryParamsYear.dateEnd = this.getYear();
    this.getDayList();
    this.getMonthList();
    this.getYearList();
  },
  methods: {
    getDay() {
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth() + 1; // 月份从0开始，所以要加1
      var day = today.getDate();
      // 格式化日期
      var formattedDate =
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day);
      return formattedDate;
    },
    getMonth() {
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth() + 1; // 月份从0开始，所以要加1

      // 格式化日期
      var formattedDate = year + "-" + (month < 10 ? "0" + month : month);
      return formattedDate;
    },
    getYear() {
      var today = new Date();
      var year = today.getFullYear();

      // 格式化日期
      var formattedDate = String(year);
      return formattedDate;
    },
    /** 查询表格列表 */
    getDayList() {
      this.dayLoading = true;
      this.$request
        .post(
          `/api/houseCount/queryHouseCount?pageSize=${this.queryParamsDay.pageSize}&pageNum=${this.queryParamsDay.pageNum}`,
          this.queryParamsDay
        )
        .then((res) => {
          if (res.code === 200) {
            this.dayList = res.data.rows;
            this.dayTotal = res.data.total;
          }
          this.dayLoading = false;
        });
    },
    /** 查询表格列表 */
    getMonthList() {
      this.monthLoading = true;
      this.$request
        .post(
          `/api/houseCount/queryHouseCount?pageSize=${this.queryParamsMonth.pageSize}&pageNum=${this.queryParamsMonth.pageNum}`,
          this.queryParamsMonth
        )
        .then((res) => {
          if (res.code == 200) {
            this.monthList = res.data.rows;
            this.monthTotal = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
          this.monthLoading = false;
        });
    },
    /** 查询表格列表 */
    getYearList() {
      this.yearLoading = true;
      this.$request
        .post(
          `/api/houseCount/queryHouseCount?pageSize=${this.queryParamsYear.pageSize}&pageNum=${this.queryParamsYear.pageNum}`,
          this.queryParamsYear
        )
        .then((res) => {
          if (res.code === 200) {
            this.yearList = res.data.rows;
            this.yearTotal = res.data.total;
          }
          this.yearLoading = false;
        });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    /** 日搜索按钮操作 */
    dayHandleQuery() {
      this.$refs["dayRef"].validate((valid) => {
        if (valid) {
          this.queryParamsDay.pageNum = 1;
          this.getDayList();
        }
      });
    },
    /** 月搜索按钮操作 */
    monthHandleQuery() {
      this.$refs["monthRef"].validate((valid) => {
        if (valid) {
          this.queryParamsMonth.pageNum = 1;
          this.getMonthList();
        }
      });
    },
    /** 年搜索按钮操作 */
    yearHandleQuery() {
      this.$refs["yearRef"].validate((valid) => {
        if (valid) {
          this.queryParamsYear.pageNum = 1;
          this.getYearList();
        }
      });
    },
  },
};
</script>
  
  <style lang="scss">
@import "../styles/variable.scss";
.house-change-container {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
}
</style>
  